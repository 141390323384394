import orgServices from '../../../services/notisphere/organization'
import SupplierModal from './SupplierModal'
import { ORGANIZATION_STATUS } from '../../../misc/commons'
import { _findObj } from '../../../misc/genericFunctions'
import { Notisphere_Supplier_AgGrid, AG_GridCustomizedTableHeaders_Key } from '../../../misc/tableHeaders'

export default {
    components: {
        SupplierModal
    },
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            search: {
                name: null,
                add1: null,
                add2: null,
                city: null,
                state: null,
                zip: null,
            },
            gridApi: null,
            columnApi: null,
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            total: 0,
            items: [],
            header: [],
            defaultHeader: [],
            org_status: ORGANIZATION_STATUS,
            supplierModalKey: 0,
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    created() {
        this.rowModelType = 'serverSide'
    },

    beforeMount() {
        this.columnDefs = Notisphere_Supplier_AgGrid
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Notisphere_Login.Supplier_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Notisphere_Login?.Supplier_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
        },
        addMoreClick() {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderInsert()
            })
        },
        onCellClicked(params) {
            if (params.colDef.field === 'status') {
                this.addMoreRespClick(params.data)
                return
            }
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'edit') {
                    this.updateSupplier(params.data.id)
                }
                if (params.event.target.dataset.action === 'delete') {
                    this.deletePendingSupplier(params.data)
                }
                return
            }else{
                this.supplierModalKey += 1
                this.$nextTick(() => {
                    this.$refs.supplierModal.forceRerenderUpdate(params.data.id)
                })
            }
        },
        // update supplier data
        updateSupplier(payload, e = null) {
            this.supplierModalKey += 1
            this.$nextTick(() => {
                this.$refs.supplierModal.forceRerenderUpdate(payload)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        // end here
        fetchData(params) {
            this.applyState(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            // params.api.sizeColumnsToFit()
            const datasource = {
                getRows(params) {
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.name = params.request?.filterModel?.name?.filter
                    that.search.add1 = params.request?.filterModel?.add1?.filter
                    that.search.add2 = params.request?.filterModel?.add2?.filter
                    that.search.city = params.request?.filterModel?.city?.filter
                    that.search.state = params.request?.filterModel?.state?.filter
                    that.search.zip = params.request?.filterModel?.zip?.filter
                    that.search.filterModel = that.gridApi.getFilterModel()
                    orgServices.findSuppliers({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search
                    })
                        .then(resp => {
                            if (!resp.error) {
                                that.total = resp.data.d.t
                                return resp.data.d.c
                            }
                        }).then(response => {

                            params.successCallback(response)
                        })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)

        },
        deletePendingSupplier(item, e) {
            this._showConfirm('You are about to delete this organization, this cannot be undone.').then(confirm => {
                if (confirm) {
                    orgServices.deletePendingSupplier(item.id).then(resp => {
                        if (!resp.error) {
                            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
                            this._showToast('Deleted successfully!')
                        }
                    })
                }
            })
            // e.stopPropagation()
        }
    }
}