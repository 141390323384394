import orgService from '../../../../services/notisphere/organization'

export default {
    data() {
        return {
            item: {
                id: null,
                name: null,
                notisphere_id: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                isSSO: false,
                idpName: null,
                zip: null,
                stt: null,

                createdBy: null,
                effectiveDate: null,

                domains: [],
                ad_first_name: null,
                ad_last_name: null,
                ad_email: null,
            },
            formType: 'INSERT'
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'New Supplier',
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Supplier',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
        },
        _status: {
            get() {
                return this.item.stt == 'VERIFIED' ? true : false
            },
            set(val) {
                if (val == true) {
                    this.item.stt = 'VERIFIED'
                }
                else {
                    this.item.stt = 'DEACTIVATED'
                }
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(id) {
            this.formType = 'UPDATE'
            this.getSupplier(id)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT') {
                if (this.item.domains.length > 0 ) {
                    this.createSupplier()
                } else {
                    this._showToast('Domains list cannot be empty', {variant: 'danger'})
                }
            }
            if(isValid && this.formType == 'UPDATE'){
                this.updateSupplier()
            }
        },
        updateSupplier() {
            orgService.updateSupplier(this.item).then(resp=>{
                if (!resp.error) {
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        createSupplier() {
            if(this.item.isSSO == null)
            {
                this.item.isSSO = false
            }
            orgService.createSupplier(this.item).then(resp=>{
                if (!resp.error) {
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
                else{
                    if (resp.data.c == 'err_user_email_exists') {
                        this.$refs.observer.setErrors({ 'ad_email': ['The user already exists.'] })
                    }

                }
            })
        },
        getSupplier(id) {
            orgService.getSupplier(id).then(resp=>{
                if(!resp.error){
                    this.item = resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        },
        removeDomain(index) {
            this.item.domains.splice(index, 1)
        },
        addDomain() {
            this.item.domains.push('')
        }
    }
}